import classnames from 'classnames';
import dynamic from 'next/dynamic';
import { useRef, useCallback } from 'react';

import BottomSmallPerks from '~/components/BottomSmallPerks/BottomSmallPerks';
import { G2Widget } from '~/components/G2Widget/G2Widget';
import { trackEvent } from '~/lib/mixpanel/mixpanel';

import { getUploadAssetsURL, createMarkup, isMobile } from '../../../../utils/functions';
import { dataTypes } from '../../../../utils/propTypes';
import RoundedButton from '../../../Buttons/RoundedButton/RoundedButton';
import {
  BUTTON_BG_COLORS,
  BUTTON_SIZES,
} from '../../../Buttons/RoundedButton/RoundedButton.constants';
import Image from '../../../Image/Image';
import SignupForm from '../../SignupForm/SignupForm';

import classes from './Hero.module.scss';

const DynamicVideo = dynamic(() => import('../../Video/Video'), {
  ssr: false,
});

const Hero = ({ content }) => {
  const popupElementOverlay = useRef();
  const ref = useRef();

  const handleVideoControl = useCallback((event) => {
    event.stopPropagation();
  }, []);

  const handleClosePopup = () => {
    ref.current.pause();
    popupElementOverlay.current.hidden = true;
    popupElementOverlay.current.setAttribute('aria-hidden', 'true');
    document.body.style.overflow = 'unset';
  };

  const handleOpenPopup = useCallback(() => {
    trackEvent('video_link_clicked');
    if (isMobile()) {
      ref.current.playOnFullscreen();
    } else {
      ref.current.play();
    }

    popupElementOverlay.current.hidden = false;
    popupElementOverlay.current.setAttribute('aria-hidden', 'false');
    document.body.style.overflow = 'hidden';
  }, []);

  const videoButtonClasses = [
    classnames(classes.Hero__watchVideo, content.elementClassnameVideo),
  ].join(' ');

  return (
    <section className={classes.Hero}>
      <div className={classes.Hero__inner}>
        <div className={classes.Hero__holder}>
          <div className={classes.Hero__contentSection}>
            <div className={classes.Hero__textualContent}>
              <span className={classes.Hero__kicker}>{content.kicker}</span>
              <h1 className={classes.Hero__title}>{content.title}</h1>
              <div
                className={classes.Hero__text}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={createMarkup(content.subtitle)}
              />
            </div>
            <div className={classes.Hero__buttonsRow}>
              <RoundedButton
                content={{
                  backgroundColor: BUTTON_BG_COLORS.LIGHT_PURPLE,
                  label: content.buttonText,
                  size: BUTTON_SIZES.REGULAR,
                  slug: 'https://app.bugbug.io/sign-up/',
                  elementId: 'sign-up-hero',
                  event: {
                    name: 'sign_up_button_clicked',
                    placement: 'hero',
                  },
                }}
              />
              {content.video ? (
                <button type="button" onClick={handleOpenPopup} className={videoButtonClasses}>
                  &#9654; {content.playButtonText}
                </button>
              ) : null}
            </div>
            <BottomSmallPerks data={content.bottomSmallPerks.map(({ label }) => label.trim())} />
            {content.newsletterForm ? (
              <SignupForm
                settings={content.globalSettings}
                perks={content.bottomSmallPerks.map(({ label }) => label.trim())}
                buttonText={content.buttonText}
                className={classes.Hero__form}
                elementClassnameSignUp={content.elementClassnameSignUp}
              />
            ) : null}
          </div>
          <div className={classes.g2RatingContainer}>
            <G2Widget
              content={{
                variant: 'row',
              }}
            />
          </div>
          <div className={classes.Hero__mediaSection}>
            <div className={classes.Hero__mediaSectionImageHolder}>
              {content.heroImage ? (
                <Image
                  src={getUploadAssetsURL(content.heroImage.url)}
                  alt={content.heroImage.alternativeText}
                  width="1160"
                  height="653"
                  priority
                  decoding="async"
                  sizes="(max-width: 1336px) 100vw, (max-width: 1920px) 1920px, 2560px"
                  quality="100"
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {content.video ? (
        <div
          className={classes.Hero__iframePopupOverlay}
          ref={popupElementOverlay}
          hidden
          aria-hidden="true"
          onClick={handleClosePopup}
        >
          <div
            className={classes.Hero__iframePopupOverlayWrapper}
            onClick={handleVideoControl}
            aria-hidden="true"
          >
            <DynamicVideo data={content.video} dynamicVideoRef={ref} />
            <button
              type="button"
              aria-label="Close popup"
              className={classes.Hero__iframePopupOverlayCloseButton}
              onClick={handleClosePopup}
            />
          </div>
        </div>
      ) : null}
    </section>
  );
};

Hero.propTypes = {
  content: dataTypes.frontpageHero.props,
};

Hero.defaultProps = {
  content: dataTypes.frontpageHero.default,
};

export default Hero;
