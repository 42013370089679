import type { BlogPosting, BreadcrumbList, Organization, WebSite, WithContext } from 'schema-dts';
import type { Article, StaticJSONSchemaType } from '~/types/models';

import { getUploadAssetsURL } from './functions';
import { getArticleUrl, getAuthorUrl, getCategoryUrl } from './urls';

export const generateBlogPostingSchema = (article: Article): WithContext<BlogPosting> => {
  const baseUrl = process.env.SITE_URL || 'https://bugbug.io';
  const articleUrl = `${baseUrl}${getArticleUrl(article)}`;
  const articleImageUrl = getUploadAssetsURL(article.featuredImage.url);

  return {
    '@context': 'https://schema.org',
    '@type': 'BlogPosting',
    '@id': articleUrl,
    mainEntityOfPage: articleUrl,
    url: articleUrl,
    headline: article.title,
    name: article.title,
    description: article.excerpt,
    datePublished: article.publishDate.split('T')[0],
    dateModified: article.updatedAt.split('T')[0],
    author: article.authors.map((author) => {
      const authorUrl = `${baseUrl}${getAuthorUrl(author)}`;
      const authorImageUrl = getUploadAssetsURL(author.image?.url ?? '');
      return {
        '@type': 'Person',
        '@id': authorUrl,
        url: authorUrl,
        name: author.name,
        image: {
          '@type': 'ImageObject',
          '@id': authorImageUrl,
          url: authorImageUrl,
          height: '120',
          width: '120',
        },
      };
    }),
    publisher: {
      '@type': 'Organization',
      '@id': baseUrl,
      name: 'BugBug',
      logo: {
        '@type': 'ImageObject',
        '@id': `${baseUrl}/logo.png`,
        url: `${baseUrl}/logo.png`,
        width: '192',
        height: '192',
      },
    },
    image: {
      '@type': 'ImageObject',
      '@id': articleImageUrl,
      url: articleImageUrl,
      height: '1500',
      width: '938',
    },
    isPartOf: {
      '@type': 'Blog',
      '@id': `${baseUrl}/blog/`,
      name: 'BugBug Blog',
      publisher: {
        '@type': 'Organization',
        '@id': baseUrl,
        name: 'BugBug',
      },
    },
  };
};

export const generateBreadcrumbListSchema = (article: Article): WithContext<BreadcrumbList> => {
  const baseUrl = process.env.SITE_URL || 'https://bugbug.io';

  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        item: {
          '@id': `${baseUrl}/blog/`,
          name: 'Blog',
        },
      },
      {
        '@type': 'ListItem',
        position: 2,
        item: {
          '@id': `${baseUrl}${getCategoryUrl(article.article_category)}`,
          name: article.article_category.name,
        },
      },
    ],
  };
};

const organizationSchema: WithContext<Organization> = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'BugBug',
  address: {
    '@type': 'PostalAddress',
    addressCountry: 'Poland',
    addressRegion: 'Silesian Voivodeship',
    addressLocality: 'Tarnowskie Góry',
    streetAddress: 'Gliwicka 35',
    postalCode: '42-600',
  },
};

const websiteSchema: WithContext<WebSite> = {
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  name: 'BugBug',
  url: 'https://bugbug.io',
};

const staticSchemas: Record<StaticJSONSchemaType, object> = {
  organization: organizationSchema,
  website: websiteSchema,
};

export const getStaticSchema = (schema?: StaticJSONSchemaType) =>
  schema ? staticSchemas[schema] : null;
