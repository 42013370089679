import classNames from 'classnames';

import type { MarkdownComponentProps } from '~/types/markdownComponents';

import classes from './ArticleFaq.module.scss';

export const ArticleFaq = ({ question, answer }: MarkdownComponentProps<'faq'>) => (
  <button className={classNames('article-faq-container', classes.questionContainer)} type="button">
    <div className={classes.question}>
      <div>{question}</div>
      <span className={classes.icon} />
    </div>
    <div className={classes.answer}>{answer}</div>
  </button>
);
