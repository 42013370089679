import { useState } from 'react';

import type { Announcement as AnnouncementProps } from '~/types/models';

import closeIcon from '~/public/assets/icons/x.svg';
import { getCookie, setCookie } from '~/utils/cookies';
import { createMarkup } from '~/utils/functions';

import classes from './Announcement.module.scss';

const Announcement = ({ content, updatedAt }: AnnouncementProps) => {
  const cookieName = `announcement-${updatedAt}-viewed`;
  const [isViewed, setIsViewed] = useState(getCookie(cookieName) ?? false);

  const handleClose = () => {
    setCookie({ name: cookieName, value: JSON.stringify(true), expiresInHours: 24 * 30 });
    setIsViewed(true);
  };

  if (isViewed) {
    return null;
  }

  return (
    <div className={classes.container}>
      {/* eslint-disable-next-line react/no-danger */}
      <div className={classes.content} dangerouslySetInnerHTML={createMarkup(content)} />
      {/* eslint-disable-next-line @next/next/no-img-element, jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
      <img
        src={closeIcon.src}
        alt="Close announcement"
        width={18}
        height={18}
        onClick={handleClose}
      />
    </div>
  );
};

export default Announcement;
