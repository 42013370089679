import classNames from 'classnames';
import Image from 'next/image';

import type { CTAWithScreenshots as CTAWithScreenshotsContent } from '~/types/data/components';

import overlayScreenshot from '~/public/assets/images/overlay-screenshot.png';
import webappScreenshot from '~/public/assets/images/webapp-screenshot.png';
import { createMarkup } from '~/utils/markdown';

import classes from './CTAWithScreenshots.module.scss';

interface CTAWithScreenshotsProps {
  content: CTAWithScreenshotsContent;
}
export const CTAWithScreenshots = ({ content }: CTAWithScreenshotsProps) => (
  <div className={classes.wrapper}>
    <div className={classes.container}>
      <div className={classes.content}>
        <p className={classes.title}>{content.title}</p>
        <div
          className={classes.description}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={createMarkup(content.details)}
        />
        <div className={classes.buttonsContainer}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://app.bugbug.io/sign-up/"
            className={classNames(classes.ctaButton, classes.yellow)}
          >
            Get started
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://bugbug.io/book-a-demo/"
            className={classNames(classes.ctaButton, classes.purple)}
          >
            Book a demo
          </a>
        </div>
      </div>
      <div className={classes.screenshots}>
        <Image
          className={classNames(classes.screenshot, classes.webappScreenshot)}
          src={webappScreenshot}
          alt=""
          width={1000}
          height={1000}
        />
        <Image
          className={classNames(classes.screenshot, classes.overlayScreenshot)}
          src={overlayScreenshot}
          alt=""
        />
      </div>
    </div>
  </div>
);
