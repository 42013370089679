import type { ReactNode } from 'react';

import classes from './RadioOption.module.scss';

interface RadioOptionProps {
  value: string;
  children: ReactNode;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
export const RadioOption = ({ children, value, checked, onChange }: RadioOptionProps) => (
  <li className={classes.radioOptionContainer}>
    <input
      type="radio"
      value={value}
      checked={checked}
      onChange={onChange}
      className={classes.radioOptionInput}
    />
    <span className={classes.radioOptionCheckmarkContainer}>
      <span className={classes.radioOptionCheckmark} data-checked={checked} />
    </span>
    <span className={classes.radioLabel}>{children}</span>
  </li>
);
