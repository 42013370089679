import * as R from 'ramda';

import type {
  Integration as IntegrationType,
  IntegrationsList as IntegrationsListType,
} from '~/types/models';

import RoundedButton from '~/components/Buttons/RoundedButton/RoundedButton';
import Image from '~/components/Image/Image';
import { getUploadAssetsURL } from '~/utils/functions';

import classes from './IntegrationsList.module.scss';

interface IntegrationsListProps {
  content: IntegrationsListType;
}

const IntegrationsList = ({ content }: IntegrationsListProps) => {
  const renderSingleIntegration = (integration: IntegrationType) => (
    <a
      key={integration.id}
      className={classes.IntegrationsList__item}
      href={integration.moreButton?.slug ?? ''}
      target="_blank"
      rel="noreferrer"
    >
      {integration.image && (
        <Image
          src={getUploadAssetsURL(integration.image.url)}
          alt={integration.name}
          height="80"
          width="110"
          objectFit="contain"
        />
      )}
      <div className={classes.IntegrationsList__title}>{integration.name}</div>
      <div className={classes.IntegrationsList__description}>{integration.description}</div>
      {integration.comingSoon ? (
        <div className={classes.IntegrationsList__comingSoon}>Coming soon</div>
      ) : (
        integration.moreButton && (
          <div className={classes.IntegrationsList__button}>
            <RoundedButton content={R.omit(['slug'], integration.moreButton)} />
          </div>
        )
      )}
    </a>
  );

  return (
    <section className={classes.IntegrationsList}>
      <div className={classes.IntegrationsList__inner}>
        {content.integrations.map(renderSingleIntegration)}
      </div>
    </section>
  );
};

export default IntegrationsList;
