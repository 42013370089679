import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import Image from 'next/image';
import { useSearchParams } from 'next/navigation';
import { Router, useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';
import { useExitIntent } from 'use-exit-intent';

import type { CSSProperties } from 'react';
import type { SinglePopup } from '~/types/models';

import { useOnIdle } from '~/hooks/useOnIdle';
import closeIcon from '~/public/assets/icons/x.svg';

import PageTemplateProvider from '../PageTemplateProvider/PageTemplateProvider';
import { usePopups } from '../Popups/Popups.context';
import { PrefetchForms } from '../PrefetchForms';

import { isRouteMatchPattern } from './Popup.helpers';
import classes from './Popup.module.scss';
import popupMotion, { backdropAnimation } from './Popup.motion';

const daysToExpire: Record<SinglePopup['popup']['visibilityCondition'], number> = {
  everyDay: 1,
  everyMonth: 30,
  everyWeek: 7,
  once: 9999,
};

interface PopupProps {
  content: SinglePopup;
}

const useShowIfMoveOutFromRoute = (
  unsavedChanges: boolean,
  patterns: string[],
  callback: () => boolean,
) => {
  const shouldHandleThisRouteChange = (url: string) =>
    patterns.some((pattern) => isRouteMatchPattern(pattern, url));
  const router = useRouter();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (unsavedChanges) {
      const routeChangeStart = (url: string) => {
        if (!shouldHandleThisRouteChange(url)) {
          const ok = callback();
          if (!ok) {
            Router.events.emit('routeChangeError');
            // eslint-disable-next-line no-throw-literal
            throw 'Abort route change. Please ignore this error.';
          }
        }
      };
      router.events.on('routeChangeStart', routeChangeStart);

      return () => {
        router.events.off('routeChangeStart', routeChangeStart);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unsavedChanges]);
};

const Popup = ({ content }: PopupProps) => {
  const { popup } = content;
  const { isOpen, open, close, cookieBarVisible, hiddenPopups } = usePopups();

  const router = useRouter();
  const id = `bb_popup_${popup.id}`;
  const settings = {
    cookie: {
      key: id,
      daysToExpire: daysToExpire[popup.visibilityCondition],
    },
    desktop: {
      useBeforeUnload: popup.useBeforeUnload,
      triggerOnMouseLeave: popup.triggerOnMouseLeave,
    },
  };
  const sourceRoutes = popup.includedUrl ? popup.includedUrl.split('\n') : [router.asPath];

  const searchParams = useSearchParams();

  const popupParam = searchParams.get('popup');

  const {
    unsubscribe,
    isUnsubscribed: isUnsubscribedCookie,
    updateSettings,
    registerHandler,
  } = useExitIntent(settings);

  const isUnsubscribed = isUnsubscribedCookie || hiddenPopups.includes(popup.id);

  useEffect(() => {
    if (popupParam === popup.id.toString()) {
      open(id);
      unsubscribe();
    }
  }, [id, open, popup.id, popupParam, unsubscribe]);

  const openOnIdle = useCallback(() => {
    if (isUnsubscribed) return;
    open(id);
    unsubscribe();
  }, [id, isUnsubscribed, open, unsubscribe]);

  useOnIdle({
    desktopMs: popup.triggerOnIdle ? popup.delayInSecondsToTrigger * 1000 : undefined,
    mobileMs: popup.triggerOnIdleMobile ? popup.delayInSecondsToTriggerMobile * 1000 : undefined,
    callback: openOnIdle,
  });

  useIsomorphicLayoutEffect(() => {
    if (!popup.showBackdrop) return;
    const originalStyle = window.getComputedStyle(document.body).overflow;
    if (isOpen(id)) {
      document.body.style.overflow = 'hidden';
    }
    // eslint-disable-next-line consistent-return
    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, [id, isOpen, popup.showBackdrop]);

  // Prevent route change if the popup is not triggered but it should
  useShowIfMoveOutFromRoute(popup.useOnRouteChange && !isUnsubscribed, sourceRoutes, () => {
    if (!isOpen(id)) {
      open(id);
      updateSettings({
        ...settings,
        desktop: {
          triggerOnIdle: false,
          useBeforeUnload: false,
          triggerOnMouseLeave: false,
        },
        mobile: {
          triggerOnIdle: false,
        },
      });
    }

    return false;
  });

  const onClose = () => {
    unsubscribe();
    close(id);
  };

  const popupStyle: CSSProperties = {
    maxWidth: popup.maxWidth,
    maxHeight: popup.maxHeight,
  };

  const containerClassNames = classNames(
    classes.container,
    classes[popup.position],
    popup.showBackdrop && classes.backdrop,
  );
  const popupClassNames = classNames(
    classes.popup,
    classes[popup.background],
    cookieBarVisible && classes.bottomOffsetMobileOnly,
    cookieBarVisible && popup.position.includes('bottom') && classes.bottomOffset,
  );

  useEffect(() => {
    registerHandler({
      id: `open-modal-${id}`,
      handler: () => {
        if (isUnsubscribed) return;
        open(id);
        unsubscribe();
      },
      context: ['onTrigger', 'onDesktop', 'onMobile'],
    });
  }, [id, isUnsubscribed, open, registerHandler, unsubscribe]);

  useEffect(
    () => () => {
      if (!isUnsubscribed && isOpen(id)) {
        unsubscribe();
      }
    },
    [id, isOpen, isUnsubscribed, unsubscribe],
  );

  return (
    <>
      <PrefetchForms content={popup.content} />
      <AnimatePresence>
        {isOpen(id) && (
          <motion.div
            className={containerClassNames}
            {...(popup.showBackdrop ? backdropAnimation : {})}
          >
            <motion.div
              style={popupStyle}
              className={popupClassNames}
              role="dialog"
              aria-label={`Popup: ${popup.title}`}
              initial="initial"
              animate="animate"
              exit="initial"
              transition={popupMotion.transition}
              variants={popupMotion.getSlideIn(popup.position)}
            >
              {/* @ts-expect-error PageTemplateProvider is not typed yet */}
              <PageTemplateProvider pageLayout={popup.content} />
              <button type="button" className={classes.close} onClick={onClose}>
                <Image src={closeIcon.src} alt="Close popup" width={18} height={18} />
              </button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Popup;
