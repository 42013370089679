import { renderToStaticMarkup } from 'react-dom/server';

import type { MarkdownComponentDetails } from '~/types/markdownComponents';

import { ArticleCtaCard } from '~/components/markdown/ArticleCtaCard/AticleCtaCard';
import { ArticleDottedFrame } from '~/components/markdown/ArticleDottedFrame/ArticleDottedFrame';
import { ArticleFaq } from '~/components/markdown/ArticleFaq/ArticleFaq';
import { ArticleTip } from '~/components/markdown/ArticleTip/ArticleTip';

function getRenderer({ name, fields }: MarkdownComponentDetails) {
  switch (name) {
    case 'cta-card':
      return <ArticleCtaCard {...fields} />;
    case 'yellow-tip':
      return <ArticleTip {...fields} variant="yellow" />;
    case 'purple-tip':
      return <ArticleTip {...fields} variant="purple" />;
    case 'dotted-frame':
      return <ArticleDottedFrame {...fields} />;
    case 'faq':
      return <ArticleFaq {...fields} />;
    default:
      return exhaustiveCheck(name);
  }
}

export function renderMarkdownComponent(args: MarkdownComponentDetails) {
  return renderToStaticMarkup(getRenderer(args));
}

function exhaustiveCheck(param: never): never {
  throw new Error(`Incorrect component type ${param}`);
}
