export const MARKDOWN_COMPONENTS = [
  {
    name: 'yellow-tip',
    fields: ['title', 'details'],
  },
  {
    name: 'purple-tip',
    fields: ['title', 'details'],
  },
  {
    name: 'cta-card',
    fields: ['title', 'details', 'cta'],
  },
  {
    name: 'dotted-frame',
    fields: ['details'],
  },
  {
    name: 'faq',
    fields: ['question', 'answer'],
  },
] as const;
