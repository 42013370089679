import classNames from 'classnames';

import type { Banner as BannerContent } from '~/types/models';

import Link from '../Link/Link';
import Media from '../Media/Media';

import classes from './Banner.module.scss';

interface BannerProps {
  content: BannerContent;
}

const Banner = ({ content }: BannerProps) => {
  if (!content.image) {
    return null;
  }

  const bannerClassName = classNames(classes.banner, classes[content.position], {
    [classes.withMarginBottom]: content.withMarginBottom,
  });

  return (
    <Link href={content.url} className={classes.container} native>
      <Media
        className={bannerClassName}
        data={content.image}
        style={{ maxWidth: content.maxWidthPx ? `${content.maxWidthPx}px` : 'auto' }}
      />
    </Link>
  );
};

export default Banner;
