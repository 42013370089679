import classNames from 'classnames';
import { useFormik } from 'formik';
import * as yup from 'yup';

import type { FormikHelpers } from 'formik';
import type { NewsletterSection as NewsletterSectionContent } from '~/types/data/components';

import { createMarkup } from '~/utils/markdown';

import styles from './NewsletterSection.module.scss';

interface NewsletterSectionProps {
  content: NewsletterSectionContent;
}

const handleSubmitAction = async (
  values: { email: string },
  helpers: FormikHelpers<{ email: string }>,
) => {
  helpers.setStatus('submitting');
  helpers.validateForm(values);

  const domain = 'https://bugbug.activehosted.com/proc.php';
  const params = {
    u: '1',
    f: '1',
    s: '',
    c: '0',
    m: '0',
    act: 'sub',
    v: '2',
    email: values.email,
    jsonp: 'true',
  };
  const url = `${domain}?${new URLSearchParams(params)}`;

  try {
    const response = await fetch(url, { method: 'POST' });
    if (response.status === 200) {
      helpers.setStatus('success');
    } else if (response.status === 409) {
      helpers.setStatus('error-already-subscribed');
    } else {
      helpers.setStatus('error-unexpected');
    }
  } catch (error) {
    helpers.setStatus('error-unexpected');
  }
};

const formInputsSchema = yup.object().shape({
  email: yup
    .string()
    .email('This field must contain a valid email.')
    .required('This field can not be empty.'),
});

export const NewsletterSection = ({
  content: { title, description, bottomText, titleVariant, background },
}: NewsletterSectionProps) => {
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: formInputsSchema,
    onSubmit: handleSubmitAction,
    validateOnBlur: false,
  });
  return (
    <div className={classNames(styles.wrapper, styles[background])}>
      {title && <p className={classNames(styles.title, styles[titleVariant])}>{title}</p>}
      <div className={styles.content}>
        {description && (
          // eslint-disable-next-line react/no-danger
          <div className={styles.description} dangerouslySetInnerHTML={createMarkup(description)} />
        )}
        {formik.status !== 'success' && (
          <form
            className={classNames(styles.form, {
              [styles.disabled]: formik.status === 'submitting',
            })}
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <div className={styles.emailInputContainer}>
              <input
                name="email"
                className={styles.emailInput}
                type="email"
                placeholder="Your e-mail"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.email && (
                <div className={styles.errorMessage}>{formik.errors.email}</div>
              )}
            </div>
            <button
              type="submit"
              disabled={formik.status === 'submitting'}
              className={styles.submitButton}
            >
              Subscribe
            </button>
          </form>
        )}
        {formik.status === 'success' && (
          <div className={styles.successMessage}>Thank you for subscribing!</div>
        )}
        {formik.status === 'error-unexpected' && (
          <div className={styles.errorMessage}>Unexpected server error. Try again later.</div>
        )}
        {formik.status === 'error-already-subscribed' && (
          <div className={styles.errorMessage}>Email already subscribed.</div>
        )}
        {bottomText && (
          // eslint-disable-next-line react/no-danger
          <div className={styles.bottomText} dangerouslySetInnerHTML={createMarkup(bottomText)} />
        )}
      </div>
    </div>
  );
};
