import type { PricingPlansList as PricingPlansListType } from '~/types/models';

import RoundedButton from '~/components/Buttons/RoundedButton';
import { useBillingCycleContext } from '~/contexts/billingCycle';

import { PricingPlan } from './PricingPlan';
import classes from './PricingPlansList.module.scss';
import { RadioOption } from './RadioOption';

import ArrowDownIcon from '~/public/assets/icons/arrow-down.svg?react';

interface PricingPlansListProps {
  content: PricingPlansListType;
}
export const PricingPlansList = ({ content }: PricingPlansListProps) => {
  const { billingCycle, setBillingCycle } = useBillingCycleContext();

  return (
    <section className={classes.container}>
      <div className={classes.header}>
        <div className={classes.titleWrapper}>
          <h2 className={classes.title}>Choose your plan</h2>
          <div className={classes.priceTaxInfo}>*Prices do not include VAT</div>
        </div>
        <ul className={classes.radioGroup}>
          <RadioOption
            value="Monthly"
            checked={billingCycle === 'Monthly'}
            onChange={() => setBillingCycle('Monthly')}
          >
            Monthly
          </RadioOption>
          <RadioOption
            value="Annual"
            checked={billingCycle === 'Annual'}
            onChange={() => setBillingCycle('Annual')}
          >
            Annually
            {content.promoMessage && (
              <span className={classes.discount}>{content.promoMessage}</span>
            )}
          </RadioOption>
        </ul>
      </div>
      <div className={classes.plansGrid}>
        {content?.plans.map((plan) => (
          <PricingPlan plan={plan} selectedBillingCycle={billingCycle} key={plan.id} />
        ))}
      </div>
      <RoundedButton
        className={classes.comparePlansButton}
        customLabelElement={
          <span className={classes.comparePlansButtonLabel}>
            <span>Compare all plans</span>
            <ArrowDownIcon className={classes.comparePlansButtonLabelIcon} />
          </span>
        }
        content={{
          label: 'Compare all plans',
          slug: '#cms-pricing-table',
          backgroundColor: 'LightPurple',
          size: 'Regular',
          withBorder: true,
        }}
      />
    </section>
  );
};
