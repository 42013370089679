import classNames from 'classnames';

import type { ClientsLogosList as ClientsLogosListProps } from '~/types/models';

import Image from '~/components/Image/Image';
import { getUploadAssetsURL } from '~/utils/functions';

import SectionTitle from '../SectionTitle/SectionTitle';

import classes from './ClientsLogosList.module.scss';

const ClientsLogosList = ({
  title,
  clients = [],
  withBottomMargin = true,
}: ClientsLogosListProps) => {
  if (!clients.length) {
    return null;
  }

  const className = [
    classNames(classes.container, {
      [classes.withBottomMargin]: withBottomMargin,
    }),
  ].join(' ');

  return (
    <section className={className}>
      <div className={classes.inner}>
        {title && <SectionTitle className={classes.title} content={{ title }} headingSize="h3" />}

        <div className={classes.logotypeList}>
          {clients.map((client) => (
            <div className={classes.logotypeListElement} key={client.id}>
              {client.logo ? (
                <Image
                  src={getUploadAssetsURL(client.logo.url)}
                  width="130"
                  height="40"
                  objectFit="contain"
                  alt={client.logo.alternativeText}
                />
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ClientsLogosList;
