import type { PricingPlan } from '~/types/models';
import type { Maybe } from '~/types/utils';

import RoundedButton from '~/components/Buttons/RoundedButton';
import { useBillingCycleContext } from '~/contexts/billingCycle';

import classes from './PricingPlanHeaderCell.module.scss';

interface PricingPlanHeaderCellProps {
  plan: Maybe<PricingPlan>;
}
export const PricingPlanHeaderCell = ({ plan }: PricingPlanHeaderCellProps) => {
  const { billingCycle } = useBillingCycleContext();
  const price = plan?.pricing.find((p) => p.priceType === billingCycle)?.price ?? 0;

  return (
    <div className={classes.container}>
      {plan && (
        <>
          <p className={classes.title}>{plan?.title}</p>
          <p className={classes.price}>${price} per month</p>
          <p className={classes.priceMobile}>${price} / mo</p>
          <RoundedButton
            content={{
              ...plan.button,
              size: undefined,
              elementClassname: classes.smallCtaButton,
              event: plan.button.event
                ? {
                    ...plan.button.event,
                    placement: 'comparison_table',
                  }
                : undefined,
            }}
          />
        </>
      )}
    </div>
  );
};
