import { createContext, useContext, useMemo, useState } from 'react';

import type { ReactNode } from 'react';

export const BillingCycleContext = createContext<{
  billingCycle: 'Annual' | 'Monthly';
  setBillingCycle: (cycle: 'Annual' | 'Monthly') => void;
}>({
  billingCycle: 'Annual',
  setBillingCycle: () => {
    //
  },
});

export const useBillingCycleContext = () => {
  const context = useContext(BillingCycleContext);

  if (!context) {
    throw new Error('useBillingCycleContext must be used within a BillingCycleContext');
  }

  return context;
};

export const BillingCycleProvider = ({ children }: { children: ReactNode }) => {
  const [billingCycle, setBillingCycle] = useState<'Annual' | 'Monthly'>('Annual');

  const value = useMemo(
    () => ({
      billingCycle,
      setBillingCycle,
    }),
    [billingCycle, setBillingCycle],
  );

  return <BillingCycleContext.Provider value={value}>{children}</BillingCycleContext.Provider>;
};
