import { useState } from 'react';

import type {
  ToolsComparisonsSection,
  ToolsComparison as ToolsComparisonType,
} from '~/types/models';

import BaseSection from '~/components/BaseSection/BaseSection';
import RoundedButton from '~/components/Buttons/RoundedButton/RoundedButton';
import { createMarkup } from '~/utils/functions';

import SectionTitle from '../Page/SectionTitle/SectionTitle';

import classes from './ToolsComparison.module.scss';

interface ToolsComparisonProps {
  content: ToolsComparisonsSection;
}

const ToolsComparison = ({ content }: ToolsComparisonProps) => {
  const [selectedComparison, setSelectedComparison] = useState<ToolsComparisonType>(
    content.comparisons[0],
  );

  const getToolClickHandler: (
    tool: ToolsComparisonType,
  ) => React.MouseEventHandler<HTMLButtonElement> = (tool) => () => {
    setSelectedComparison(tool);
  };

  const renderBox = (title: string, description: string) => (
    <div className={classes.comparisonBox}>
      <div className={classes.comparisonTitle}>{title}</div>
      <div
        className={classes.comparisonDescription}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={createMarkup(description)}
      />
    </div>
  );

  return (
    <BaseSection background="Transparent" contentClassName={classes.sectionContent}>
      <SectionTitle
        content={{ title: content.title }}
        headingSize="h2"
        className={classes.sectionTitle}
      />

      <div className={classes.options}>
        {content.comparisons.map((comparison) => (
          <RoundedButton
            className={classes.option}
            type="button"
            key={comparison.id}
            onClick={getToolClickHandler(comparison)}
            content={{
              label: comparison.title,
              backgroundColor: 'DarkPurple',
              size: 'Regular',
              withBorder: comparison !== selectedComparison,
            }}
          />
        ))}
      </div>
      <div className={classes.content}>
        {renderBox('Without BugBug', selectedComparison.referenceToolDescription)}
        {renderBox('With BugBug', selectedComparison.secondToolDescription)}
      </div>
    </BaseSection>
  );
};

export default ToolsComparison;
