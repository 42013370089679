import type { SidebarTitle as SidebarTitleContent } from '~/types/models';

import classes from './SidebarTitle.module.scss';

interface SidebarTitleProps {
  content: SidebarTitleContent;
}

const SidebarTitle = ({ content }: SidebarTitleProps) => (
  <div className={classes.title}>
    <strong>{content.title}</strong>
  </div>
);

export default SidebarTitle;
