import classNames from 'classnames';

import type { ReactNode } from 'react';
import type { PlanFeatureValue } from '~/types/models';

import { createMarkup } from '~/utils/markdown';

import classes from './FeatureValueCell.module.scss';

import CheckIcon from '~/public/assets/icons/check-regular.svg?react';
import CrossIcon from '~/public/assets/icons/cross.svg?react';
import MinusIcon from '~/public/assets/icons/minus.svg?react';

const ICONS: Record<Exclude<PlanFeatureValue['type'], 'custom'>, ReactNode> = {
  checkIcon: <CheckIcon className={classNames(classes.icon, classes.checkIcon)} />,
  crossIcon: <CrossIcon className={classNames(classes.icon, classes.crossIcon)} />,
  minusIcon: <MinusIcon className={classNames(classes.icon, classes.minusIcon)} />,
};

interface FeatureValueCellProps {
  value: PlanFeatureValue;
}
export const FeatureValueCell = ({ value }: FeatureValueCellProps) => (
  <div className={classes.container} key={`feature-value-${value.id}`}>
    {value.type === 'custom' ? (
      // eslint-disable-next-line react/no-danger
      <span dangerouslySetInnerHTML={createMarkup(value.custom, true)} />
    ) : (
      ICONS[value.type]
    )}
  </div>
);
