/* eslint-disable no-restricted-syntax */
/* eslint-disable no-continue */
/* eslint-disable no-param-reassign */
import hljs from 'highlight.js';
import htmlReactParser from 'html-react-parser';
import MarkdownIt from 'markdown-it';
import markdownAnchor from 'markdown-it-anchor';
import markdownAttrs from 'markdown-it-attrs';
import markdownCenterText from 'markdown-it-center-text';
import emoji from 'markdown-it-emoji';
import markdownTOC from 'markdown-it-table-of-contents';
import { parse } from 'node-html-parser';

import type { ReactNode } from 'react';
import type { MarkdownComponentDetails } from '~/types/markdownComponents';

import { MARKDOWN_COMPONENTS } from '../constants/markdownComponents';

import { isSameHostname } from './browser';
import { renderMarkdownComponent } from './renderMarkdownComponent';

/**
 * Transport markdown schema to clean HTML string.
 *
 * dangerouslySetInnerHTML
 * https://pl.reactjs.org/docs/dom-elements.html#dangerouslysetinnerhtml
 *
 * Markdown to HTML convert?!
 * https://strapi.io/blog/v3-alpha-12-graphql-rich-text-editor-redesigned-dashboard
 *
 * @export
 * @param {string} markdownContent
 * @param {boolean} [noWrapper=false]
 * @return {string}
 */
export function createMarkup(
  markdownContent?: string,
  noWrapper = false,
  openAllLinksInNewTab = false,
): { __html: string } {
  // CKEditor uses GH Flavored Markdown and automatically escapes some characters, which would break our custom elements
  const parsedContent = markdownContent
    ?.replaceAll('\\[TABLE\\_OF\\_CONTENTS\\]', '[TABLE_OF_CONTENTS]')
    ?.replace(/\\->(.*?)\\<-/g, '->$1<-')
    ?.replace(/~(.*?)~/g, '~~$1~~')
    ?.replace(/` `/g, '`\n\n`');

  if (!parsedContent) {
    return { __html: '' };
  }

  const md: MarkdownIt = new MarkdownIt({
    html: true,
    highlight: (str, lang) => {
      if (lang === 'embedHtml') {
        return `<div class="markdown-highlight markdown-highlight--embed">${str}</div>`;
      }

      if (lang && hljs.getLanguage(lang)) {
        try {
          return `<pre class="markdown-highlight markdown-highlight--${lang}"><code class="hljs">${
            hljs.highlight(str, { language: lang, ignoreIllegals: true }).value
          }</code></pre>`;
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error while highlighting code', error);
        }
      }

      return `<pre class="markdown-highlight"><code class="hljs">${md.utils.escapeHtml(
        str,
      )}</code></pre>`;
    },
  });

  md.use(emoji);
  md.use(markdownTOC, {
    containerClass: 'table-of-contents',
    includeLevel: [2, 3],
    listType: 'ol',
    markerPattern: /^\[TABLE_OF_CONTENTS\]/im,
  });

  md.use(markdownAnchor);
  md.use(markdownCenterText);
  md.use(markdownAttrs, {
    allowedAttributes: ['id', 'class', 'rel', 'target'],
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const linkRender = (tokens, idx, options, env, self) =>
    self.renderToken(tokens, idx, env, options);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  md.renderer.rules.link_open = (tokens, idx, options, env, self) => {
    const link = tokens[idx];

    if (!link?.attrs?.[0]?.[1].includes('bugbug.io')) {
      link.attrPush(['rel', 'nofollow']);
    }

    const href = link.attrGet('href');
    if (href && (openAllLinksInNewTab || !isSameHostname(href))) {
      link.attrSet('rel', 'noreferrer');
      link.attrSet('target', '_blank');
    }

    return linkRender(tokens, idx, options, env, self);
  };

  const defaultCodeRender = md.renderer.rules.code_inline ?? (() => '');
  md.renderer.rules.code_inline = (...args) => {
    const [tokens] = args;
    const [codeBlock] = tokens;
    if (!codeBlock || codeBlock.tag !== 'code' || !codeBlock.content) {
      return defaultCodeRender(...args);
    }

    const details = parseMarkdownComponent(codeBlock.content);
    if (details.length === 0) {
      return defaultCodeRender(...args);
    }

    return details.map(renderMarkdownComponent).join('');
  };

  return { __html: noWrapper ? md.renderInline(parsedContent) : md.render(parsedContent) };
}

export function generateTableOfContents(markdownContent: string) {
  // CKEditor uses GH Flavored Markdown and automatically escapes some characters, which would break our custom elements
  const parsedContent = markdownContent
    ?.replaceAll('\\[TABLE\\_OF\\_CONTENTS\\]', '[TABLE_OF_CONTENTS]')
    ?.replace(/\\->(.*?)\\<-/g, '->$1<-')
    ?.replace(/~(.*?)~/g, '~~$1~~');

  if (!parsedContent) {
    return { __html: '' };
  }

  const md: MarkdownIt = new MarkdownIt({
    html: true,
  });

  md.use(emoji);
  md.use(markdownTOC, {
    containerClass: 'table-of-contents-desktop',
    includeLevel: [2, 3],
    listType: 'ol',
    markerPattern: /^\[TABLE_OF_CONTENTS\]/im,
  });

  const rendered = md.render(parsedContent);
  const root = parse(rendered);
  const table = root.querySelector('.table-of-contents-desktop');

  return { __html: table?.outerHTML || '' };
}

function parseMarkdownComponent(source: string): MarkdownComponentDetails[] {
  const components: MarkdownComponentDetails[] = [];
  for (const component of MARKDOWN_COMPONENTS) {
    const componentRegex = new RegExp(
      `<custom-${component.name}>(.*?)</custom-${component.name}>`,
      's',
    );

    const componentMatch = source.match(componentRegex);
    if (!componentMatch) continue;

    const componentSource = componentMatch[1];
    if (!componentSource) continue;

    const fields = component.fields.reduce((acc, field) => {
      const regex = new RegExp(
        `<${component.name}-${field}-md>(.*?)</${component.name}-${field}-md>`,
        's',
      );
      const match = componentSource.match(regex);
      const stringSource = match
        ? match[1]
            // Fix unterminated list item
            .replace(/( {2}\* {3}.+ {2})(.+\S)$/gm, '$1*   $2  ')
            // Fix unordered list by replacing end space with newline
            .replace(/( \* {3}.+?)(?= \*[^*]|$)/gm, '\n$1\n\n')
            // Fix ordered list by replacing end space with newline
            .replace(/( \d+\. {2}.+?)(?= \d+\.)/gm, '\n$1\n\n')
            // Replace double spaces with newlines
            .replace(/([^*][^ *\d][^ ])( {2})/g, '$1\n\n')
            // Replace double spaces with newlines after bold text
            .replace(/(\*\*)( {2})/g, '$1\n\n')
        : '';

      const { __html } = createMarkup(stringSource.trim(), false, true);
      acc[field] = htmlReactParser(__html);
      return acc;
    }, {} as Record<(typeof component.fields)[number], ReactNode>);
    components.push({
      name: component.name,
      fields,
    });
  }

  return components;
}
