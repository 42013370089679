import * as R from 'ramda';
import { useContext } from 'react';

import { SharedDataContext } from '~/contexts/sharedData';
import { COMPONENT_TYPE } from '~/utils/constants';

import BlogListContainer from '../Content/Blog/ListContainer/ListContainer';
import EmptyContent from '../Content/EmptyContent/EmptyContent';
import FrontPageHero from '../Content/Frontpage/Hero/Hero';
import Testimonials from '../Content/Frontpage/Testimonials/Testimonials';
import CaseStudyList from '../Content/Page/CaseStudyList/CaseStudyList';
import CaseStudyTestimonial from '../Content/Page/CaseStudyTestimonial/CaseStudyTestimonial';
import ClientsLogosList from '../Content/Page/ClientsLogosList/ClientsLogosList';
import { ComparePlansTable } from '../Content/Page/ComparePlansTable/ComparePlansTable';
import Content from '../Content/Page/Content/Content';
import CTASection from '../Content/Page/CTASection/CTASection';
import FaqSection from '../Content/Page/FaqSection/FaqSection';
import FullWidthSection from '../Content/Page/FullWidthSection/FullWidthSection';
import Hero from '../Content/Page/Hero/Hero';
import PerksTable from '../Content/Page/PerksTable/PerksTable';
import WebflowContent from '../Content/Page/WebflowContent/WebflowContent';
import SignupForm from '../Content/SignupForm/SignupForm';

import { COMPONENT_BY_SECTION } from './PageTemplateProvider.constants';

// eslint-disable-next-line no-underscore-dangle
const getKey = (section) => `${section.__component}-${section.id}`;

// TODO: refactor this component to use same props (key, content, pageData) and move them to components map
const PageTemplateProvider = ({ pageLayout = [], pageData = {} }) => {
  const sharedData = useContext(SharedDataContext);
  const { settings: globalSettings } = sharedData;

  const renderFullWidthSectionContent = (section) => (
    <FullWidthSection key={getKey(section)} {...section} />
  );

  const renderClientsLogosList = (section) => (
    <ClientsLogosList key={getKey(section)} {...section} />
  );

  const renderCTASection = (section) => <CTASection key={getKey(section)} data={section} />;

  const renderFullWidthBanner = (section) => (
    <FullWidthSection key={getKey(section)} {...section} />
  );

  const renderCaseStudiesList = (section) => <CaseStudyList key={getKey(section)} {...section} />;

  // @deprecated - use CustomHTML instead
  const renderWebflowContent = (section) => (
    <WebflowContent content={section.content} type={section.type} key={getKey(section)} />
  );

  const renderFrontpageHeroContent = (section) => (
    <FrontPageHero content={{ ...section, globalSettings }} key={getKey(section)} />
  );

  const renderTestimonials = (section) => <Testimonials {...section} key={getKey(section)} />;

  const renderFaqSection = (faq, section) => (
    <FaqSection content={faq} options={section} key={getKey(section)} />
  );

  // @deprecated - use ServicePerks instead
  const renderPerkSection = (section) => <PerksTable data={section.perks} key={getKey(section)} />;

  const renderPageHero = (section) => <Hero key={getKey(section)} data={section} />;

  const renderContent = (section) => (
    <Content content={section.content} type={section.type} key={getKey(section)} />
  );

  const renderArticleContent = (section) => (
    <Content content={section.body} type="Full" key={getKey(section)} />
  );

  const renderCustomHTML = (section) => (
    <WebflowContent content={section.code.html} key={getKey(section)} />
  );

  const renderSignupForm = (section) => (
    <SignupForm
      buttonText={section.buttonText}
      buttonUrl={section.buttonUrl}
      key={getKey(section)}
      settings={globalSettings}
      perks={section.perks}
    />
  );

  const renderBlogPosts = (section) => (
    <BlogListContainer
      blogPosts={section.posts}
      pagination={section.pagination}
      key={getKey(section)}
    />
  );

  const renderCaseStudyTestimonial = (section) => (
    <CaseStudyTestimonial content={section.caseStudy} key={getKey(section)} />
  );

  const renderComparePlansTable = (section) => {
    const pricingPlans = pageLayout.find(
      // eslint-disable-next-line no-underscore-dangle
      (item) => item.__component === 'page-components.page-service-pricing',
    )?.plans;

    return (
      <ComparePlansTable content={section} pricingPlans={pricingPlans} key={getKey(section)} />
    );
  };

  const renderGenericSection = (section) => {
    // eslint-disable-next-line no-underscore-dangle
    const Component = COMPONENT_BY_SECTION[section.__component];

    return Component ? (
      <Component
        content={section}
        sharedData={sharedData}
        pageData={pageData}
        key={getKey(section)}
      />
    ) : null;
  };

  const renderSection = R.cond([
    [R.propEq('__component', COMPONENT_TYPE.COLLAB_BRANDS), renderClientsLogosList],
    [R.propEq('__component', COMPONENT_TYPE.CTA_SECTION), renderCTASection],
    [R.propEq('__component', COMPONENT_TYPE.FULL_WIDTH_BANNER), renderFullWidthBanner],
    [R.propEq('__component', COMPONENT_TYPE.CASE_STUDIES_LIST), renderCaseStudiesList],
    [R.propEq('__component', COMPONENT_TYPE.WEBFLOW_CONTENT), renderWebflowContent],
    [
      R.propEq('__component', COMPONENT_TYPE.FRONTPAGE_FULL_WIDTH_BANNER),
      renderFullWidthSectionContent,
    ],
    [R.propEq('__component', COMPONENT_TYPE.FRONTPAGE_HERO), renderFrontpageHeroContent],
    [R.propEq('__component', COMPONENT_TYPE.TESTIMONIALS), renderTestimonials],
    [R.propEq('__component', COMPONENT_TYPE.PAGE_HERO), renderPageHero],
    [R.propEq('__component', COMPONENT_TYPE.CONTENT), renderContent],
    [R.propEq('__component', COMPONENT_TYPE.PERKS), renderPerkSection],
    [R.propEq('__component', COMPONENT_TYPE.SIGNUP_FORM), renderSignupForm],
    [R.propEq('__component', COMPONENT_TYPE.CASE_STUDY_TESTIMONIAL), renderCaseStudyTestimonial],
    [R.propEq('__component', COMPONENT_TYPE.CUSTOM_HTML), renderCustomHTML],
    [
      R.propEq('__component', COMPONENT_TYPE.FAQ_SECTION),
      renderFaqSection.bind(this, pageData.faqData),
    ],
    [R.propEq('__component', COMPONENT_TYPE.COMPARE_PLANS_TABLE), renderComparePlansTable],

    // Article components
    [R.propEq('__component', COMPONENT_TYPE.ARTICLE_CONTENT), renderArticleContent],

    // Custom components; not available in strapi panel
    [R.propEq('__component', COMPONENT_TYPE.BLOG_POSTS), renderBlogPosts],

    [R.T, renderGenericSection],
  ]);

  return pageLayout ? pageLayout.map(renderSection) : <EmptyContent />;
};

export default PageTemplateProvider;
