import type { MarkdownComponentProps } from '~/types/markdownComponents';

import styles from './ArticleCtaCard.module.scss';

export const ArticleCtaCard = ({ title, details, cta }: MarkdownComponentProps<'cta-card'>) => (
  <div className={styles.container}>
    <div className={styles.colorIndent} />
    <div className={styles.title}>{title}</div>
    <div className={styles.details}>{details}</div>
    <a
      target="_blank"
      rel="noreferrer"
      href="https://app.bugbug.io/sign-up/"
      className={styles.ctaButton}
    >
      {cta}
    </a>
  </div>
);
