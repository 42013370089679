import type { PageComponentType, SeoComponentType } from '~/types/models';

export const CUSTOM_TAGS = {
  TABLE_OF_CONTENTS: '[TABLE_OF_CONTENTS]',
} as const;

export const TYPE_OF_AUTHORS = {
  STANDARD: 'standard',
  EXTENDED: 'extended',
  DETAILED: 'detailed',
} as const;

export const NUMBER_OF_COLUMNS = {
  TWO: 'two',
  THREE: 'three',
} as const;

export const SEO_COMPONENT_TYPE: Record<string, SeoComponentType> = {
  BASIC: 'meta-components.seo',
  OPENGRAPH: 'meta-components.facebook-og',
  TWITTER: 'meta-components.twitter',
  FACEBOOK: 'meta-components.facebook-og',
} as const;

export const COMPONENT_TYPE: Record<string, PageComponentType> = {
  EXTRA_SPACE: 'page-components.extra-space',
  CONTENT_TITLE: 'page-components.content-title',
  COLLAB_BRANDS: 'page-components.page-collab-brands',
  CTA_SECTION: 'page-components.page-cta-container',
  FULL_WIDTH_BANNER: 'page-components.page-full-width-banner',
  CASE_STUDIES_LIST: 'page-components.page-case-studies',
  INTEGRATIONS_LIST: 'page-components.integrations',
  CUSTOM_HTML: 'page-components.custom-html-code',
  TOOLS_COMPARISON: 'page-components.tools-comparison',
  TABLE_COMPARE_SECTION: 'page-components.table-section',
  WEBFLOW_CONTENT: 'primitives.webflow-content',
  HALF_IMAGE_SECTION: 'page-components.page-half-image-description',
  FRONTPAGE_HALF_IMAGE_SECTION: 'frontpage-components.frontpage-description-with-image',
  SOLUTIONS_LIST: 'page-components.solutions',
  PERKS: 'page-components.perks',
  SERVICE_PERKS: 'frontpage-components.frontpage-perks',
  SECTION_TITLE: 'page-components.page-section-title',
  FRONTPAGE_SECTION_TITLE: 'frontpage-components.frontpage-section-title',
  FRONTPAGE_FULL_WIDTH_BANNER: 'frontpage-components.frontpage-full-width-banner',
  CONTENT: 'page-components.full-width-content',
  PAGE_HERO: 'page-components.page-hero',
  FRONTPAGE_HERO: 'frontpage-components.frontpage-hero',
  OPINION_SLIDER: 'page-components.page-opinion-slider',
  PRICING_PLANS: 'page-components.page-service-pricing',
  FAQ_SECTION: 'page-components.page-faq',
  CONTACT_SECTION: 'page-components.page-contact',
  ABOUT_SECTION: 'page-components.page-about-us',
  BOOK_DEMO: 'page-components.page-book-a-demo',
  JOB_OFFERS_LIST: 'page-components.job-offers-list',
  SIGNUP_FORM: 'page-components.signup-form',
  TESTIMONIALS: 'frontpage-components.testimonials',
  TESTIMONIAL: 'primitives.testimonial',
  HERO_WITH_FORM: 'page-components.page-hero-with-form',
  CASE_STUDY_TESTIMONIAL: 'page-components.single-case-study',
  BANNER: 'page-components.banner',
  SIDEBAR_TITLE: 'page-components.sidebar-headline',
  ARTICLE_CONTENT: 'article-components.content',
  ROUNDED_BUTTON: 'primitives.rounded-button',
  AUTHORS_LIST: 'page-components.all-authors-list',
  CATEGORIES_LIST: 'page-components.all-categories-list',
  ARTICLES_LIST: 'page-components.articles-list',
  RECENT_ARTICLES_LIST: 'page-components.recent-articles',
  POPUP: 'page-components.popup',
  POPUP_TITLE: 'popup-components.popup-title',
  HERO_CTA_SECTION: 'page-components.hero-cta-section',
  ACTIVE_CAMPAIGN_FORM: 'page-components.active-campaign-form',
  STICKY_BAR_HEADER: 'sticky-components.sticky-bar-header',
  COMPARE_PLANS_TABLE: 'page-components.compare-plans-table',
} as const;

export const BACK_BUTTON_DATA = {
  BLOG: { path: '/blog/', page: 'blog' },
  UPDATES: { path: '/updates/', page: 'updates' },
} as const;

export const EMPTY_LIST_TEXT = {
  ARTICLES: 'There is no content yet',
  CATEGORY: 'There are no articles in this category yet',
} as const;
