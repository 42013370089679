import classNames from 'classnames';

import type { MarkdownComponentProps } from '~/types/markdownComponents';

import styles from './ArticleTip.module.scss';

export const ArticleTip = ({
  title,
  details,
  variant,
}: MarkdownComponentProps<'yellow-tip' | 'purple-tip'> & {
  variant: 'yellow' | 'purple';
}) => (
  <div className={classNames(styles.container, styles[variant])}>
    <div className={classNames(styles.colorIndent, styles[variant])} />
    <div className={styles.title}>{title}</div>
    {details && details.toString().length > 0 && <div className={styles.details}>{details}</div>}
  </div>
);
