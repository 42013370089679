import type { PlanExtraPerk, PlanPerk } from '~/types/models';

import Help from '~/components/Help';
import { createMarkup } from '~/utils/functions';

import classes from './PlanFeature.module.scss';

import CheckIcon from '~/public/assets/icons/check-regular.svg?react';
import HomeIcon from '~/public/assets/icons/home.svg?react';
import UploadCloudIcon from '~/public/assets/icons/upload-cloud.svg?react';
import UsersIcon from '~/public/assets/icons/users.svg?react';

export const FEATURE_ICONS = {
  users: <UsersIcon className={classes.mainFeatureIcon} />,
  home: <HomeIcon className={classes.mainFeatureIcon} />,
  uploadCloud: <UploadCloudIcon className={classes.mainFeatureIcon} />,
  check: <CheckIcon className={classes.checkIcon} />,
};

interface PlanFeatureProps {
  icon?: keyof typeof FEATURE_ICONS;
  feature: PlanExtraPerk | PlanPerk;
}
export const PlanFeature = ({ icon, feature }: PlanFeatureProps) => (
  <li className={classes.item} aria-describedby={`renderFeaturesListElements-${feature.id}`}>
    <Help content={feature.tooltip}>
      <div className={classes.container}>
        <div className={classes.iconContainer}>{FEATURE_ICONS[icon ?? 'check']}</div>
        <span
          className={classes.label}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={createMarkup(
            'element' in feature ? feature.element : feature.content,
            true,
          )}
        />
      </div>
    </Help>
  </li>
);
