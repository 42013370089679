import classnames from 'classnames';

import type { PricingPlan as PricingPlanType } from '~/types/models';

import RoundedButton from '~/components/Buttons/RoundedButton/RoundedButton';

import { PlanFeature } from './PlanFeature';
import classes from './PricingPlan.module.scss';

const CURRENCY_SYMBOLS = {
  euro: 'EUR',
  dolar: '$',
  custom: '',
} as const;

interface PricingPlanProps {
  plan: PricingPlanType;
  selectedBillingCycle: 'Monthly' | 'Annual';
}

export const PricingPlan = ({ plan, selectedBillingCycle }: PricingPlanProps) => {
  const selectedPrice = plan.pricing.find((price) => price.priceType === selectedBillingCycle);
  const billingCycleDescription =
    selectedBillingCycle === 'Monthly' || selectedPrice?.price === 0
      ? 'per month'
      : 'per month, billed annually';

  return (
    <div className={classes.outerWrapper}>
      {plan.popular && (
        <div className={classes.badge}>
          <p className={classes.badgeText}>Most popular</p>
        </div>
      )}
      <div className={classnames(classes.planContainer, plan.popular && classes.primaryBorder)}>
        <div>
          <h3 className={classes.planTitle}>{plan.title}</h3>
          <p className={classes.planDescription}>{plan.smallDescription}</p>
        </div>
        {selectedPrice && (
          <div>
            <p className={classes.planPrice}>
              {CURRENCY_SYMBOLS[selectedPrice.currency]}
              {selectedPrice.price.toLocaleString('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </p>
            <p className={classes.planDescription}>{billingCycleDescription}</p>
          </div>
        )}
        <ul className={classes.mainFeaturesList}>
          {plan.mainPerks.map((feature) => (
            <PlanFeature key={feature.id} icon={feature.icon} feature={feature} />
          ))}
        </ul>
        <RoundedButton className={classes.manageSubscriptionButton} content={plan.button} />
        <div className={classes.featuresSection}>
          <p className={classes.featuresTitle}>{plan.extraPerksHeader}</p>
          <ul className={classes.featuresList}>
            {plan.extraPerks.map((feature) => (
              <PlanFeature key={feature.id} feature={feature} />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
