import classnames from 'classnames';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';

import { dataTypes } from '../../../../../utils/propTypes';

import classes from './NewsletterForm.module.scss';

const formInputsSchema = yup.object().shape({
  email: yup
    .string('This field must be a string.')
    .email('This field must contain a valid email.')
    .required('This field can not be empty.'),
});

const FORM_MESSAGES = {
  409: 'Email already signed up.',
  DEFAULT: 'Message was not send by server error.',
  SUCCESS: 'Thank you for subscribing!',
  EMPTY: '',
};

const handleSubmitAction = async (values, actions) => {
  actions.setStatus({ submitting: true, msg: FORM_MESSAGES.EMPTY, show: false, isError: false });
  actions.validateForm(values);

  const domain = 'https://bugbug.activehosted.com/proc.php';
  const params = {
    u: 1,
    f: 1,
    s: '',
    c: 0,
    m: 0,
    act: 'sub',
    v: 2,
    email: values.email,
    jsonp: true,
  };
  const url = `${domain}?${new URLSearchParams(params)}`;
  const defaultOptions = { method: 'POST', mode: 'no-cors' };

  try {
    await fetch(url, defaultOptions);
    actions.setStatus({
      submitting: false,
      msg: FORM_MESSAGES.SUCCESS,
      show: true,
      isError: false,
    });
  } catch (error) {
    actions.setStatus({
      submitting: false,
      msg: FORM_MESSAGES[error.status] || FORM_MESSAGES.DEFAULT,
      show: true,
      isError: true,
    });
  }
};

const NewsletterForm = ({ buttonWithBorder, elementClassname }) => {
  const buttonClasses = [
    classnames(classes.NewsletterForm__buttonInput, {
      [classes.withBorder]: buttonWithBorder,
    }),
    elementClassname,
  ].join(' ');

  return (
    <div className={classes.NewsletterForm}>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{
          email: '',
        }}
        validationSchema={formInputsSchema}
        onSubmit={handleSubmitAction}
      >
        {({ status }) => (
          <Form className={classes.NewsletterForm__inner} noValidate>
            <div className={classes.NewsletterForm__inputContainer}>
              <Field
                name="email"
                type="email"
                placeholder="Your e-mail"
                aria-label="Your e-mail"
                className={classes.NewsletterForm__emailInput}
              />
              <ErrorMessage
                name="email"
                className={classes.NewsletterForm__errorMessage}
                component="div"
              />
            </div>
            <button className={buttonClasses} type="submit" disabled={status?.submitting || false}>
              <span
                className={classes.NewsletterForm__buttonSpinner}
                hidden={status?.submitting || false ? '' : 'hidden'}
              />
              <span>Subscribe</span>
            </button>
            {status?.show ? (
              <div
                className={
                  status?.isError
                    ? classes.NewsletterForm__formErrorMessage
                    : classes.NewsletterForm__formMessage
                }
              >
                {status.msg}
              </div>
            ) : null}
          </Form>
        )}
      </Formik>
    </div>
  );
};

NewsletterForm.propTypes = {
  buttonWithBorder: dataTypes.buttonWithBorder.props,
  elementClassname: dataTypes.elementClassname.props,
};

NewsletterForm.defaultProps = {
  buttonWithBorder: dataTypes.buttonWithBorder.default,
  elementClassname: dataTypes.elementClassname.default,
};

export default NewsletterForm;
