import classNames from 'classnames';

import type { ComparePlansTable as ComparePlansTableContent, PricingPlan } from '~/types/models';

import Help from '~/components/Help';

import classes from './ComparePlansTable.module.scss';
import { FeatureValueCell } from './FeatureValueCell';
import { PricingPlanHeaderCell } from './PricingPlanHeaderCell';

interface ComparePlansTableProps {
  content: ComparePlansTableContent;
  pricingPlans?: PricingPlan[];
}
export const ComparePlansTable = ({ content, pricingPlans }: ComparePlansTableProps) => {
  const PLANS_COUNT = pricingPlans?.length ?? 3;

  return (
    <div className={classes.container} id="cms-pricing-table">
      <div className={classes.table}>
        {pricingPlans && (
          <>
            <PricingPlanHeaderCell plan={null} />
            {pricingPlans.map((plan) => (
              <PricingPlanHeaderCell key={`pricing-plan-${plan.id}`} plan={plan} />
            ))}
          </>
        )}
        {content.featuresGroups.map((featuresGroup) => (
          <>
            <div
              className={classes.tableHeader}
              key={`feature-header-${featuresGroup.name}-${featuresGroup.id}`}
            >
              {featuresGroup.name}
            </div>
            {featuresGroup.features.map((feature) => (
              <>
                <div
                  className={classNames(classes.tableCell, classes.featureNameCell)}
                  key={`feature-name-${feature.name}-${feature.id}`}
                >
                  <Help
                    content={feature.tooltip}
                    className={classes.tooltipContainer}
                    tooltipClassName={classes.tooltip}
                  >
                    {feature.name}
                  </Help>
                </div>
                {feature.value.slice(0, PLANS_COUNT).map((value) => (
                  <FeatureValueCell key={`feature-value-${value.id}`} value={value} />
                ))}
              </>
            ))}
          </>
        ))}
      </div>
    </div>
  );
};
